<template>
  <div>
    <v-toolbar
        flat
    >
<!--      <v-btn-->
<!--          outlined-->
<!--          class="mr-4"-->
<!--          color="grey darken-2"-->
<!--          @click="setToday"-->
<!--      >-->
<!--        Hoje-->
<!--      </v-btn>-->
<!--      <v-btn-->
<!--          fab-->
<!--          text-->
<!--          small-->
<!--          color="grey darken-2"-->
<!--          @click="prev"-->
<!--      >-->
<!--        <v-icon small>-->
<!--          mdi-chevron-left-->
<!--        </v-icon>-->
<!--      </v-btn>-->
<!--      <v-btn-->
<!--          fab-->
<!--          text-->
<!--          small-->
<!--          color="grey darken-2"-->
<!--          @click="next"-->
<!--      >-->
<!--        <v-icon small>-->
<!--          mdi-chevron-right-->
<!--        </v-icon>-->
<!--      </v-btn>-->
      <v-toolbar-title v-if="$refs.calendar">
        {{ $refs.calendar.title }}{{quadraFiltrada}}
      </v-toolbar-title>
      <v-spacer></v-spacer>
<!--      <v-menu-->
<!--          bottom-->
<!--          right-->
<!--      >-->
<!--        <template v-slot:activator="{ on, attrs }">-->
<!--          <v-btn-->
<!--              outlined-->
<!--              color="grey darken-2"-->
<!--              v-bind="attrs"-->
<!--              v-on="on"-->
<!--          >-->
<!--            <span>{{ typeToLabel[type] }}</span>-->
<!--            <v-icon right>-->
<!--              mdi-menu-down-->
<!--            </v-icon>-->
<!--          </v-btn>-->
<!--        </template>-->
<!--        <v-list>-->
<!--          <v-list-item @click="type = 'day'">-->
<!--            <v-list-item-title>Diario</v-list-item-title>-->
<!--          </v-list-item>-->
<!--          <v-list-item @click="type = 'week'">-->
<!--            <v-list-item-title>Semanal</v-list-item-title>-->
<!--          </v-list-item>-->
<!--          <v-list-item @click="type = 'month'">-->
<!--            <v-list-item-title>Mensal</v-list-item-title>-->
<!--          </v-list-item>-->
<!--          <v-list-item @click="type = '4day'">-->
<!--            <v-list-item-title>4 Dias</v-list-item-title>-->
<!--          </v-list-item>-->
<!--        </v-list>-->
<!--      </v-menu>-->
    </v-toolbar>
    <v-calendar
        ref="calendar"
        color="primary"
        :interval-height="30"
        :event-height="30"
        v-model="focus"
        :events="elements"
        :first-time="'06:00'"
        @click:event="showEvent"
        @click:more="viewDay"
        :type="type"
    >
      <template v-slot:event="{event}">
        <div class="d-flex flex-column flex-grow-1 px-2">
          <p class="ma-0">
            {{event.details.descricao_idTipoTurma}},
            {{event.details.nome_idProfessor}}<!--,
            {{event.details.descricao_idQuadra}}-->
          </p>
        </div>
      </template>

    </v-calendar>
    <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
    >
      <v-card
          color="grey lighten-4"
          min-width="350px"
          flat
      >
        <v-toolbar
            :color="selectedEvent.color"
            dark
        >
          <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
          <v-spacer/>
          <v-btn icon @click="selectedOpen = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text v-if="selectedEvent.details">
          <v-text-field class="mb-2" hide-details disabled label="Tipo Turma" :value="selectedEvent.details.descricao_idTipoTurma"/>
          <v-text-field class="mb-2" hide-details disabled label="Quadra" :value="selectedEvent.details.descricao_idQuadra"/>
          <v-text-field class="mb-2" hide-details disabled label="Hora Inicio" :value="selectedEvent.details.hora_inicio_idHorario"/>
          <v-text-field class="mb-2" hide-details disabled label="Professor" :value="selectedEvent.details.nome_idProfessor"/>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn

              color="danger"
              @click="clickDelete"
          >
            Remover
          </v-btn>
          <v-spacer/>
          <v-btn color="primary" text @click="$emit('click:edit', selectedEvent.details)">
            Editar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>

import * as moment from 'moment';
import {findAllCalendar} from '../../api/turmas';
import {findOne as findOneQuadra} from '../../api/quadras';

/**
 * @emits click:edit
 * @emit click:remove
 */
export default {
  name: "TurmasCalendar",
  props: {
    searchValues: {
      type: Object,
    },
  },
  data() {
    return {
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      descricaoQuadraFiltro: "",
      focus: '',
      typeToLabel: {
        month: 'Mensal',
        week: 'Semanal',
        day: 'Diario',
        '4day': '4 Dias',
      },
      type: 'week',
      selectedItem: null,
      menuOptions: [
        {
          action: 'edit',
          text: 'Editar',
          icon: 'mdi-pencil'
        },
        {
          action: 'remove',
          text: 'Remover',
          icon: 'mdi-delete',
          color: 'error'
        }
      ],
      menu: {
        positionX: 0,
        positionY: 0,
        show: false
      },
      loading: false,
      error: null,
      pageOptions: {
        page: 1
      },
      itemsPerPage: 0,
      totalElements: 0,
      elements: [],
    }
  },
  computed: {
    selectedItemName() {
      return this.selectedItem?.descricao_idQuadra
    },
    quadraFiltrada() {
      return this.descricaoQuadraFiltro !="" ? " - "+this.descricaoQuadraFiltro : "";
    }
  },
  watch: {
    searchValues: {
      deep: true,
      handler() {
        this.refresh();
        this.getQuadra();
      }
    },
    'pageOptions.page': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.refresh(newValue);
          this.getQuadra();
        }
      }
    }
  },
  methods: {
    setToday () {
      this.type = 'day';
      this.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    async getQuadra() {
      let id = parseInt(this.searchValues?.idQuadra);
      this.descricaoQuadraFiltro = "";
      if (id > 0){
        try {
          const quadra = await findOneQuadra(id);
          this.descricaoQuadraFiltro = quadra.dados[0]?.descricao;
        } catch (err) {
          //console.log(err);
        }
      }
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    showMenu(event, item) {
      this.selectedItem = !item.item ? item : item.item;
      this.$refs.menu.open(event);
    },
    evtCatFn(v) {
      return 'Tipo 1';
    },
    onClickRow(row) {
      this.$emit('click:row', row);
    },
    clickDelete() {
      this.$emit('click:remove',this.selectedEvent.details);
      this.selectedOpen = false;
    },
    async refresh() {
      try {
        this.error = null;
        this.loading = true;
        if ( parseInt(this.searchValues?.idQuadra) > 0){
          const page = await findAllCalendar(this.pageOptions.page, this.searchValues);

          this.itemsPerPage = page.elementsPerPage;
          this.totalElements = page.totalElements;

          function getAllDaysInMonth(day, time) {
            const days = [];
            const monday = moment()
                .startOf('month')
                .day(day);
            if (monday.date() > 7) monday.add(7,'d');
            const month = monday.month();
            while(month === monday.month()){
              days.push(monday.format('YYYY-MM-DD')+'T'+time);
              monday.add(7,'d');
            }
            return days;
          }

          const values = [];
          page.elements.forEach(elemento => {
            const days = getAllDaysInMonth(elemento.diaSemana, elemento.hora_inicio_idHorario);

            values.push(...days.map(d => ({
              name: `${elemento.descricao_idTipoTurma} - ${elemento.descricao_idQuadra}\n${elemento.nome_idProfessor}`,
              timed: true,
              start: new Date(d),
              end: moment(d).add(1,'h').toDate(),
              details: elemento,
              color: 'secondary'
            })));

          });

          this.elements = values;
          this.pageOptions.page = page.currentPage;
        } else {
          this.$toast.error(`Informe a Quadra para carregar o Quadro de Aulas.`, {
            timeout: 6000
          })
        }
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    if ( parseInt(this.searchValues?.idQuadra) > 0){
      this.pageOptions.page = 1;
      this.refresh();
      this.getQuadra();
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-calendar-daily_head-day {
    .v-calendar-daily_head-day-label {
      cursor: default !important;
    }
    button {
      cursor: default !important;
      pointer-events: none !important;

    }
  }
}
</style>