<template>
  <base-view
      @click:add="criar"
      @update:search-values="updateSearch"
      :search-columns="searchColumns">
    <template v-slot:actions>
      <div @click="viewMode = 'calendar'"
           :class="[viewMode === 'calendar' ? 'filter-toggle-button opened' : 'filter-toggle-button']">
        <v-icon :color="viewMode === 'calendar' ? 'primary' : 'grey'">mdi-calendar</v-icon>
      </div>
      <div @click="viewMode = 'listView'"
           :class="[viewMode === 'listView' ? 'filter-toggle-button opened' : 'filter-toggle-button']">
        <v-icon :color="viewMode === 'listView' ? 'primary' : 'grey'">mdi-format-list-text</v-icon>
      </div>
    </template>

    <create-edit-turma-dialog :item="item" v-model="editDialogOpened"
                              @onSave="() => $refs.dataTable.refresh()"/>

    <div>
      <turmas-calendar
          v-if="viewMode === 'calendar' && !this.$vuetify.breakpoint.mobile"
          ref="dataTable"
          @click:edit="editar"
          @click:remove="remover"
          :search-values="searchValues"/>
      <turmas-data-table
          v-else
          ref="dataTable"
          @click:edit="editar"
          @click:remove="remover"
          :search-values="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import TurmasDataTable from "../../components/shared/datatables/TurmasDataTable";
import BaseView from "../BaseView";
import {remove} from "../../api/turmas";
import CreateEditTurmaDialog from "../../components/shared/dialogs/CreateEditTurmaDialog";
import {mapActions} from "vuex";
import {ItemDeDominio} from "../../api/itemsDominio";
import {findAll as findAllHorario} from "../../api/horarios";
import {findAll as findAllQuadra} from "../../api/quadras";
import {findAll as findAllProfessor} from '../../api/professores';
import {findAll as findAllTipoTurma} from '../../api/tipoTurma';
import Calendario from "./Calendario";
import TurmasCalendar from "./TurmasCalendar";
import {ApiError} from "../../api/core";

export default {
  name: "index",
  components: {TurmasCalendar, Calendario, CreateEditTurmaDialog, BaseView, TurmasDataTable},
  data() {
    return {
      viewMode: 'calendar',
      item: null,
      editDialogOpened: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [
        {
          name: '* Quadra',
          column: 'idQuadra',
          type: 'autocomplete',
          optionsFactory: (search) => findAllQuadra(1,{
            descricao: search
          })
              .then(r => r.elements.map(e => ({
                key: e.id,
                valor: e.descricao
              })))
        },
        {
          name: 'Horario',
          column: 'idHorario',
          type: 'autocomplete',
          optionsFactory: (search) => findAllHorario(1,{
            horaInicio: search
          })
              .then(r => r.elements.map(e => ({
                key: e.id,
                valor: e.range
              })))
        },
        {
          name: 'Tipo Turma',
          column: 'idTipoTurma',
          type: 'autocomplete',
          optionsFactory: (search) => findAllTipoTurma(1, {
            descricao: search
          })
              .then(r => r.elements.map(e => ({
                key: e.id,
                valor: e.descricao
              })))
        },
        {
          name: 'Professor',
          column: 'idProfessor',
          type: 'autocomplete',
          optionsFactory: (search) => findAllProfessor(1, {
            nome: search
          })
              .then(r => r.elements.map(e => ({
                key: e.id,
                valor: e.nome
              })))
        },
        {
          name: 'Dia Semana',
          column: 'diaSemana',
          type: 'autocomplete',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.ARR_DIAS_SEMANA
          })
        }        
      ]
    }
  },
  methods: {
    ...mapActions('itemsDominio', ['fetch']),
    updateSearch(search) {
      this.searchValues = search;
    },
    criar() {
      this.item = {};
      this.editDialogOpened = true;
    },
    editar(aula) {
      this.item = JSON.parse(JSON.stringify(aula));
      this.editDialogOpened = true;
    },
    async remover({id}) {
      try {
        await remove(id);
        this.$toast.success(`Quadro de Aula removido com sucesso.`, {
          timeout: 3000
        });
        this.$refs.dataTable.refresh();
      } catch (err) {
        if(err instanceof ApiError) {
          this.$toast.error(err.message, {
           timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível remover o Quadro de Aula.`, {
            timeout: 6000
          })
        }
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">

.filter-toggle-button {
  border-right: 1px solid #dedede;
  height: 60px;
  width: 60px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 1s ease 0s;
  cursor: pointer;

  &.opened, &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}


::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>