<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar
            flat
        >
<!--          <v-btn-->
<!--              outlined-->
<!--              class="mr-4"-->
<!--              color="grey darken-2"-->
<!--              @click="setToday"-->
<!--          >-->
<!--            Today-->
<!--          </v-btn>-->
          <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="prev"
          >
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="next"
          >
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
<!--          <v-menu-->
<!--              bottom-->
<!--              right-->
<!--          >-->
<!--            <template v-slot:activator="{ on, attrs }">-->
<!--              <v-btn-->
<!--                  outlined-->
<!--                  color="grey darken-2"-->
<!--                  v-bind="attrs"-->
<!--                  v-on="on"-->
<!--              >-->
<!--                <span>{{ typeToLabel[type] }}</span>-->
<!--                <v-icon right>-->
<!--                  mdi-menu-down-->
<!--                </v-icon>-->
<!--              </v-btn>-->
<!--            </template>-->
<!--            <v-list>-->
<!--              <v-list-item @click="type = 'day'">-->
<!--                <v-list-item-title>Day</v-list-item-title>-->
<!--              </v-list-item>-->
<!--              <v-list-item @click="type = 'week'">-->
<!--                <v-list-item-title>Week</v-list-item-title>-->
<!--              </v-list-item>-->
<!--              <v-list-item @click="type = 'month'">-->
<!--                <v-list-item-title>Month</v-list-item-title>-->
<!--              </v-list-item>-->
<!--              <v-list-item @click="type = '4day'">-->
<!--                <v-list-item-title>4 days</v-list-item-title>-->
<!--              </v-list-item>-->
<!--            </v-list>-->
<!--          </v-menu>-->
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events2"
            :event-color="getEventColor"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @change="updateRange"
        ></v-calendar>
        <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
        >
          <v-card
              color="grey lighten-4"
              min-width="350px"
              flat
          >
            <v-toolbar
                :color="selectedEvent.color"
                dark
            >
              <v-btn icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon>mdi-heart</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <span v-html="selectedEvent.details"></span>
            </v-card-text>
            <v-card-actions>
              <v-btn
                  text
                  color="secondary"
                  @click="selectedOpen = false"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script>
import * as moment from 'moment';
export default {
  name: 'Calendario',
  data: () => ({
    focus: '',
    type: 'week',
    typeToLabel: {
      month: 'Month',
      week: 'Week',
      day: 'Day',
      '4day': '4 Days',
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events2: [
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nEG Laranja - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-07T20:00:00.000Z",
        "end": "2022-02-07T21:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nEG Laranja - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-14T20:00:00.000Z",
        "end": "2022-02-14T21:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nEG Laranja - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-21T20:00:00.000Z",
        "end": "2022-02-21T21:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nEG Laranja - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-28T20:00:00.000Z",
        "end": "2022-02-28T21:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nEG 14 a 16 anos - Carlos",
        "color": "red",
        "timed": true,
        "start": "2022-02-07T21:00:00.000Z",
        "end": "2022-02-07T22:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nEG 14 a 16 anos - Carlos",
        "color": "red",
        "timed": true,
        "start": "2022-02-14T21:00:00.000Z",
        "end": "2022-02-14T22:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nEG 14 a 16 anos - Carlos",
        "color": "red",
        "timed": true,
        "start": "2022-02-21T21:00:00.000Z",
        "end": "2022-02-21T22:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nEG 14 a 16 anos - Carlos",
        "color": "red",
        "timed": true,
        "start": "2022-02-28T21:00:00.000Z",
        "end": "2022-02-28T22:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nCard",
        "color": "red",
        "timed": true,
        "start": "2022-02-01T12:00:00.000Z",
        "end": "2022-02-01T13:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nCard",
        "color": "red",
        "timed": true,
        "start": "2022-02-08T12:00:00.000Z",
        "end": "2022-02-08T13:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nCard",
        "color": "red",
        "timed": true,
        "start": "2022-02-15T12:00:00.000Z",
        "end": "2022-02-15T13:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nCard",
        "color": "red",
        "timed": true,
        "start": "2022-02-22T12:00:00.000Z",
        "end": "2022-02-22T13:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nEG Laranja - Eliney",
        "color": "red",
        "timed": true,
        "start": "2022-02-01T21:00:00.000Z",
        "end": "2022-02-01T22:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nEG Laranja - Eliney",
        "color": "red",
        "timed": true,
        "start": "2022-02-08T21:00:00.000Z",
        "end": "2022-02-08T22:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nEG Laranja - Eliney",
        "color": "red",
        "timed": true,
        "start": "2022-02-15T21:00:00.000Z",
        "end": "2022-02-15T22:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nEG Laranja - Eliney",
        "color": "red",
        "timed": true,
        "start": "2022-02-22T21:00:00.000Z",
        "end": "2022-02-22T22:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nEG Laranja - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-01T22:00:00.000Z",
        "end": "2022-02-01T23:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nEG Laranja - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-08T22:00:00.000Z",
        "end": "2022-02-08T23:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nEG Laranja - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-15T22:00:00.000Z",
        "end": "2022-02-15T23:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nEG Laranja - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-22T22:00:00.000Z",
        "end": "2022-02-22T23:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nK 10",
        "color": "red",
        "timed": true,
        "start": "2022-02-01T23:00:00.000Z",
        "end": "2022-02-02T00:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nK 10",
        "color": "red",
        "timed": true,
        "start": "2022-02-08T23:00:00.000Z",
        "end": "2022-02-09T00:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nK 10",
        "color": "red",
        "timed": true,
        "start": "2022-02-15T23:00:00.000Z",
        "end": "2022-02-16T00:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nK 10",
        "color": "red",
        "timed": true,
        "start": "2022-02-22T23:00:00.000Z",
        "end": "2022-02-23T00:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nPomocao Maes - Renato",
        "color": "red",
        "timed": true,
        "start": "2022-02-03T10:00:00.000Z",
        "end": "2022-02-03T11:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nPomocao Maes - Renato",
        "color": "red",
        "timed": true,
        "start": "2022-02-10T10:00:00.000Z",
        "end": "2022-02-10T11:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nPomocao Maes - Renato",
        "color": "red",
        "timed": true,
        "start": "2022-02-17T10:00:00.000Z",
        "end": "2022-02-17T11:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nPomocao Maes - Renato",
        "color": "red",
        "timed": true,
        "start": "2022-02-24T10:00:00.000Z",
        "end": "2022-02-24T11:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nVictor Silva",
        "color": "red",
        "timed": true,
        "start": "2022-02-03T19:00:00.000Z",
        "end": "2022-02-03T20:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nVictor Silva",
        "color": "red",
        "timed": true,
        "start": "2022-02-10T19:00:00.000Z",
        "end": "2022-02-10T20:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nVictor Silva",
        "color": "red",
        "timed": true,
        "start": "2022-02-17T19:00:00.000Z",
        "end": "2022-02-17T20:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nVictor Silva",
        "color": "red",
        "timed": true,
        "start": "2022-02-24T19:00:00.000Z",
        "end": "2022-02-24T20:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nEG Laranja - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-03T21:00:00.000Z",
        "end": "2022-02-03T22:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nEG Laranja - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-10T21:00:00.000Z",
        "end": "2022-02-10T22:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nEG Laranja - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-17T21:00:00.000Z",
        "end": "2022-02-17T22:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nEG Laranja - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-24T21:00:00.000Z",
        "end": "2022-02-24T22:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nIntermed - Deivisson",
        "color": "red",
        "timed": true,
        "start": "2022-02-03T22:00:00.000Z",
        "end": "2022-02-03T23:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nIntermed - Deivisson",
        "color": "red",
        "timed": true,
        "start": "2022-02-10T22:00:00.000Z",
        "end": "2022-02-10T23:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nIntermed - Deivisson",
        "color": "red",
        "timed": true,
        "start": "2022-02-17T22:00:00.000Z",
        "end": "2022-02-17T23:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\nIntermed - Deivisson",
        "color": "red",
        "timed": true,
        "start": "2022-02-24T22:00:00.000Z",
        "end": "2022-02-24T23:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\niniciantes - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-04T19:00:00.000Z",
        "end": "2022-02-04T20:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\niniciantes - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-11T19:00:00.000Z",
        "end": "2022-02-11T20:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\niniciantes - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-18T19:00:00.000Z",
        "end": "2022-02-18T20:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 1\niniciantes - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-25T19:00:00.000Z",
        "end": "2022-02-25T20:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 1\nLocacao",
        "color": "red",
        "timed": true,
        "start": "2022-02-07T19:00:00.000Z",
        "end": "2022-02-07T20:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 1\nLocacao",
        "color": "red",
        "timed": true,
        "start": "2022-02-14T19:00:00.000Z",
        "end": "2022-02-14T20:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 1\nLocacao",
        "color": "red",
        "timed": true,
        "start": "2022-02-21T19:00:00.000Z",
        "end": "2022-02-21T20:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 1\nLocacao",
        "color": "red",
        "timed": true,
        "start": "2022-02-28T19:00:00.000Z",
        "end": "2022-02-28T20:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 1\nEG Vermelha - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-01T19:00:00.000Z",
        "end": "2022-02-01T20:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 1\nEG Vermelha - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-08T19:00:00.000Z",
        "end": "2022-02-08T20:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 1\nEG Vermelha - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-15T19:00:00.000Z",
        "end": "2022-02-15T20:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 1\nEG Vermelha - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-22T19:00:00.000Z",
        "end": "2022-02-22T20:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 1\nIntermed - Eliney",
        "color": "red",
        "timed": true,
        "start": "2022-02-02T19:00:00.000Z",
        "end": "2022-02-02T20:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 1\nIntermed - Eliney",
        "color": "red",
        "timed": true,
        "start": "2022-02-09T19:00:00.000Z",
        "end": "2022-02-09T20:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 1\nIntermed - Eliney",
        "color": "red",
        "timed": true,
        "start": "2022-02-16T19:00:00.000Z",
        "end": "2022-02-16T20:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 1\nIntermed - Eliney",
        "color": "red",
        "timed": true,
        "start": "2022-02-23T19:00:00.000Z",
        "end": "2022-02-23T20:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 1\nEG Vermelha - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-03T12:00:00.000Z",
        "end": "2022-02-03T13:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 1\nEG Vermelha - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-10T12:00:00.000Z",
        "end": "2022-02-10T13:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 1\nEG Vermelha - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-17T12:00:00.000Z",
        "end": "2022-02-17T13:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 1\nEG Vermelha - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-24T12:00:00.000Z",
        "end": "2022-02-24T13:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nPomocao Maes - Renato",
        "color": "red",
        "timed": true,
        "start": "2022-02-07T18:00:00.000Z",
        "end": "2022-02-07T19:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nPomocao Maes - Renato",
        "color": "red",
        "timed": true,
        "start": "2022-02-14T18:00:00.000Z",
        "end": "2022-02-14T19:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nPomocao Maes - Renato",
        "color": "red",
        "timed": true,
        "start": "2022-02-21T18:00:00.000Z",
        "end": "2022-02-21T19:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nPomocao Maes - Renato",
        "color": "red",
        "timed": true,
        "start": "2022-02-28T18:00:00.000Z",
        "end": "2022-02-28T19:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nT 11",
        "color": "red",
        "timed": true,
        "start": "2022-02-07T22:00:00.000Z",
        "end": "2022-02-07T23:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nT 11",
        "color": "red",
        "timed": true,
        "start": "2022-02-14T22:00:00.000Z",
        "end": "2022-02-14T23:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nT 11",
        "color": "red",
        "timed": true,
        "start": "2022-02-21T22:00:00.000Z",
        "end": "2022-02-21T23:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nT 11",
        "color": "red",
        "timed": true,
        "start": "2022-02-28T22:00:00.000Z",
        "end": "2022-02-28T23:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nTreino Adultos - Deivisson",
        "color": "red",
        "timed": true,
        "start": "2022-02-07T23:00:00.000Z",
        "end": "2022-02-08T00:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nTreino Adultos - Deivisson",
        "color": "red",
        "timed": true,
        "start": "2022-02-14T23:00:00.000Z",
        "end": "2022-02-15T00:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nTreino Adultos - Deivisson",
        "color": "red",
        "timed": true,
        "start": "2022-02-21T23:00:00.000Z",
        "end": "2022-02-22T00:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nTreino Adultos - Deivisson",
        "color": "red",
        "timed": true,
        "start": "2022-02-28T23:00:00.000Z",
        "end": "2022-03-01T00:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\niniciante - Carlos",
        "color": "red",
        "timed": true,
        "start": "2022-02-01T10:00:00.000Z",
        "end": "2022-02-01T11:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\niniciante - Carlos",
        "color": "red",
        "timed": true,
        "start": "2022-02-08T10:00:00.000Z",
        "end": "2022-02-08T11:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\niniciante - Carlos",
        "color": "red",
        "timed": true,
        "start": "2022-02-15T10:00:00.000Z",
        "end": "2022-02-15T11:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\niniciante - Carlos",
        "color": "red",
        "timed": true,
        "start": "2022-02-22T10:00:00.000Z",
        "end": "2022-02-22T11:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nK 10",
        "color": "red",
        "timed": true,
        "start": "2022-02-02T18:00:00.000Z",
        "end": "2022-02-02T19:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nK 10",
        "color": "red",
        "timed": true,
        "start": "2022-02-09T18:00:00.000Z",
        "end": "2022-02-09T19:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nK 10",
        "color": "red",
        "timed": true,
        "start": "2022-02-16T18:00:00.000Z",
        "end": "2022-02-16T19:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nK 10",
        "color": "red",
        "timed": true,
        "start": "2022-02-23T18:00:00.000Z",
        "end": "2022-02-23T19:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nEG 11 a 14 anos - Deivisson",
        "color": "red",
        "timed": true,
        "start": "2022-02-02T20:00:00.000Z",
        "end": "2022-02-02T21:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nEG 11 a 14 anos - Deivisson",
        "color": "red",
        "timed": true,
        "start": "2022-02-09T20:00:00.000Z",
        "end": "2022-02-09T21:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nEG 11 a 14 anos - Deivisson",
        "color": "red",
        "timed": true,
        "start": "2022-02-16T20:00:00.000Z",
        "end": "2022-02-16T21:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nEG 11 a 14 anos - Deivisson",
        "color": "red",
        "timed": true,
        "start": "2022-02-23T20:00:00.000Z",
        "end": "2022-02-23T21:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nK 8",
        "color": "red",
        "timed": true,
        "start": "2022-02-02T21:00:00.000Z",
        "end": "2022-02-02T22:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nK 8",
        "color": "red",
        "timed": true,
        "start": "2022-02-09T21:00:00.000Z",
        "end": "2022-02-09T22:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nK 8",
        "color": "red",
        "timed": true,
        "start": "2022-02-16T21:00:00.000Z",
        "end": "2022-02-16T22:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nK 8",
        "color": "red",
        "timed": true,
        "start": "2022-02-23T21:00:00.000Z",
        "end": "2022-02-23T22:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nLocacao",
        "color": "red",
        "timed": true,
        "start": "2022-02-02T22:00:00.000Z",
        "end": "2022-02-02T23:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nLocacao",
        "color": "red",
        "timed": true,
        "start": "2022-02-09T22:00:00.000Z",
        "end": "2022-02-09T23:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nLocacao",
        "color": "red",
        "timed": true,
        "start": "2022-02-16T22:00:00.000Z",
        "end": "2022-02-16T23:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nLocacao",
        "color": "red",
        "timed": true,
        "start": "2022-02-23T22:00:00.000Z",
        "end": "2022-02-23T23:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nCard",
        "color": "red",
        "timed": true,
        "start": "2022-02-02T23:00:00.000Z",
        "end": "2022-02-03T00:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nCard",
        "color": "red",
        "timed": true,
        "start": "2022-02-09T23:00:00.000Z",
        "end": "2022-02-10T00:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nCard",
        "color": "red",
        "timed": true,
        "start": "2022-02-16T23:00:00.000Z",
        "end": "2022-02-17T00:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nCard",
        "color": "red",
        "timed": true,
        "start": "2022-02-23T23:00:00.000Z",
        "end": "2022-02-24T00:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nTreino Equipe",
        "color": "red",
        "timed": true,
        "start": "2022-02-03T23:00:00.000Z",
        "end": "2022-02-04T00:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nTreino Equipe",
        "color": "red",
        "timed": true,
        "start": "2022-02-10T23:00:00.000Z",
        "end": "2022-02-11T00:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nTreino Equipe",
        "color": "red",
        "timed": true,
        "start": "2022-02-17T23:00:00.000Z",
        "end": "2022-02-18T00:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\nTreino Equipe",
        "color": "red",
        "timed": true,
        "start": "2022-02-24T23:00:00.000Z",
        "end": "2022-02-25T00:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\niniciante - Carlos",
        "color": "red",
        "timed": true,
        "start": "2022-02-04T18:00:00.000Z",
        "end": "2022-02-04T19:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\niniciante - Carlos",
        "color": "red",
        "timed": true,
        "start": "2022-02-11T18:00:00.000Z",
        "end": "2022-02-11T19:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\niniciante - Carlos",
        "color": "red",
        "timed": true,
        "start": "2022-02-18T18:00:00.000Z",
        "end": "2022-02-18T19:00:00.000Z"
      },
      {
        "name": "Novo TIpo - Quadra 1\niniciante - Carlos",
        "color": "red",
        "timed": true,
        "start": "2022-02-25T18:00:00.000Z",
        "end": "2022-02-25T19:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nBasico - Eliney",
        "color": "red",
        "timed": true,
        "start": "2022-02-07T14:00:00.000Z",
        "end": "2022-02-07T15:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nBasico - Eliney",
        "color": "red",
        "timed": true,
        "start": "2022-02-14T14:00:00.000Z",
        "end": "2022-02-14T15:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nBasico - Eliney",
        "color": "red",
        "timed": true,
        "start": "2022-02-21T14:00:00.000Z",
        "end": "2022-02-21T15:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nBasico - Eliney",
        "color": "red",
        "timed": true,
        "start": "2022-02-28T14:00:00.000Z",
        "end": "2022-02-28T15:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nT 12",
        "color": "red",
        "timed": true,
        "start": "2022-02-07T11:00:00.000Z",
        "end": "2022-02-07T12:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nT 12",
        "color": "red",
        "timed": true,
        "start": "2022-02-14T11:00:00.000Z",
        "end": "2022-02-14T12:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nT 12",
        "color": "red",
        "timed": true,
        "start": "2022-02-21T11:00:00.000Z",
        "end": "2022-02-21T12:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nT 12",
        "color": "red",
        "timed": true,
        "start": "2022-02-28T11:00:00.000Z",
        "end": "2022-02-28T12:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nExpress Eliney ",
        "color": "red",
        "timed": true,
        "start": "2022-02-07T18:00:00.000Z",
        "end": "2022-02-07T19:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nExpress Eliney ",
        "color": "red",
        "timed": true,
        "start": "2022-02-14T18:00:00.000Z",
        "end": "2022-02-14T19:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nExpress Eliney ",
        "color": "red",
        "timed": true,
        "start": "2022-02-21T18:00:00.000Z",
        "end": "2022-02-21T19:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nExpress Eliney ",
        "color": "red",
        "timed": true,
        "start": "2022-02-28T18:00:00.000Z",
        "end": "2022-02-28T19:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nEG 11 a 14 anos - Deivisson",
        "color": "red",
        "timed": true,
        "start": "2022-02-07T19:00:00.000Z",
        "end": "2022-02-07T20:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nEG 11 a 14 anos - Deivisson",
        "color": "red",
        "timed": true,
        "start": "2022-02-14T19:00:00.000Z",
        "end": "2022-02-14T20:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nEG 11 a 14 anos - Deivisson",
        "color": "red",
        "timed": true,
        "start": "2022-02-21T19:00:00.000Z",
        "end": "2022-02-21T20:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nEG 11 a 14 anos - Deivisson",
        "color": "red",
        "timed": true,
        "start": "2022-02-28T19:00:00.000Z",
        "end": "2022-02-28T20:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nEG 11 a 14 anos - Deivisson",
        "color": "red",
        "timed": true,
        "start": "2022-02-07T23:00:00.000Z",
        "end": "2022-02-08T00:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nEG 11 a 14 anos - Deivisson",
        "color": "red",
        "timed": true,
        "start": "2022-02-14T23:00:00.000Z",
        "end": "2022-02-15T00:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nEG 11 a 14 anos - Deivisson",
        "color": "red",
        "timed": true,
        "start": "2022-02-21T23:00:00.000Z",
        "end": "2022-02-22T00:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nEG 11 a 14 anos - Deivisson",
        "color": "red",
        "timed": true,
        "start": "2022-02-28T23:00:00.000Z",
        "end": "2022-03-01T00:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nAvancado - Renato",
        "color": "red",
        "timed": true,
        "start": "2022-02-01T21:00:00.000Z",
        "end": "2022-02-01T22:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nAvancado - Renato",
        "color": "red",
        "timed": true,
        "start": "2022-02-08T21:00:00.000Z",
        "end": "2022-02-08T22:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nAvancado - Renato",
        "color": "red",
        "timed": true,
        "start": "2022-02-15T21:00:00.000Z",
        "end": "2022-02-15T22:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nAvancado - Renato",
        "color": "red",
        "timed": true,
        "start": "2022-02-22T21:00:00.000Z",
        "end": "2022-02-22T22:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nIntermed - Victor ",
        "color": "red",
        "timed": true,
        "start": "2022-02-01T23:00:00.000Z",
        "end": "2022-02-02T00:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nIntermed - Victor ",
        "color": "red",
        "timed": true,
        "start": "2022-02-08T23:00:00.000Z",
        "end": "2022-02-09T00:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nIntermed - Victor ",
        "color": "red",
        "timed": true,
        "start": "2022-02-15T23:00:00.000Z",
        "end": "2022-02-16T00:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nIntermed - Victor ",
        "color": "red",
        "timed": true,
        "start": "2022-02-22T23:00:00.000Z",
        "end": "2022-02-23T00:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nBasico - Eliney",
        "color": "red",
        "timed": true,
        "start": "2022-02-02T14:00:00.000Z",
        "end": "2022-02-02T15:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nBasico - Eliney",
        "color": "red",
        "timed": true,
        "start": "2022-02-09T14:00:00.000Z",
        "end": "2022-02-09T15:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nBasico - Eliney",
        "color": "red",
        "timed": true,
        "start": "2022-02-16T14:00:00.000Z",
        "end": "2022-02-16T15:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nBasico - Eliney",
        "color": "red",
        "timed": true,
        "start": "2022-02-23T14:00:00.000Z",
        "end": "2022-02-23T15:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nIntermed - Carlos",
        "color": "red",
        "timed": true,
        "start": "2022-02-02T11:00:00.000Z",
        "end": "2022-02-02T12:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nIntermed - Carlos",
        "color": "red",
        "timed": true,
        "start": "2022-02-09T11:00:00.000Z",
        "end": "2022-02-09T12:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nIntermed - Carlos",
        "color": "red",
        "timed": true,
        "start": "2022-02-16T11:00:00.000Z",
        "end": "2022-02-16T12:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nIntermed - Carlos",
        "color": "red",
        "timed": true,
        "start": "2022-02-23T11:00:00.000Z",
        "end": "2022-02-23T12:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nPomocao Maes - Renato",
        "color": "red",
        "timed": true,
        "start": "2022-02-02T12:00:00.000Z",
        "end": "2022-02-02T13:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nPomocao Maes - Renato",
        "color": "red",
        "timed": true,
        "start": "2022-02-09T12:00:00.000Z",
        "end": "2022-02-09T13:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nPomocao Maes - Renato",
        "color": "red",
        "timed": true,
        "start": "2022-02-16T12:00:00.000Z",
        "end": "2022-02-16T13:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nPomocao Maes - Renato",
        "color": "red",
        "timed": true,
        "start": "2022-02-23T12:00:00.000Z",
        "end": "2022-02-23T13:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nK 9",
        "color": "red",
        "timed": true,
        "start": "2022-02-02T19:00:00.000Z",
        "end": "2022-02-02T20:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nK 9",
        "color": "red",
        "timed": true,
        "start": "2022-02-09T19:00:00.000Z",
        "end": "2022-02-09T20:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nK 9",
        "color": "red",
        "timed": true,
        "start": "2022-02-16T19:00:00.000Z",
        "end": "2022-02-16T20:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nK 9",
        "color": "red",
        "timed": true,
        "start": "2022-02-23T19:00:00.000Z",
        "end": "2022-02-23T20:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nAvancado - Carlos",
        "color": "red",
        "timed": true,
        "start": "2022-02-02T21:00:00.000Z",
        "end": "2022-02-02T22:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nAvancado - Carlos",
        "color": "red",
        "timed": true,
        "start": "2022-02-09T21:00:00.000Z",
        "end": "2022-02-09T22:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nAvancado - Carlos",
        "color": "red",
        "timed": true,
        "start": "2022-02-16T21:00:00.000Z",
        "end": "2022-02-16T22:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nAvancado - Carlos",
        "color": "red",
        "timed": true,
        "start": "2022-02-23T21:00:00.000Z",
        "end": "2022-02-23T22:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nEG Laranja - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-03T17:00:00.000Z",
        "end": "2022-02-03T18:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nEG Laranja - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-10T17:00:00.000Z",
        "end": "2022-02-10T18:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nEG Laranja - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-17T17:00:00.000Z",
        "end": "2022-02-17T18:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nEG Laranja - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-24T17:00:00.000Z",
        "end": "2022-02-24T18:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nK 9",
        "color": "red",
        "timed": true,
        "start": "2022-02-04T12:00:00.000Z",
        "end": "2022-02-04T13:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nK 9",
        "color": "red",
        "timed": true,
        "start": "2022-02-11T12:00:00.000Z",
        "end": "2022-02-11T13:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nK 9",
        "color": "red",
        "timed": true,
        "start": "2022-02-18T12:00:00.000Z",
        "end": "2022-02-18T13:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nK 9",
        "color": "red",
        "timed": true,
        "start": "2022-02-25T12:00:00.000Z",
        "end": "2022-02-25T13:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nIndividual",
        "color": "red",
        "timed": true,
        "start": "2022-02-04T17:00:00.000Z",
        "end": "2022-02-04T18:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nIndividual",
        "color": "red",
        "timed": true,
        "start": "2022-02-11T17:00:00.000Z",
        "end": "2022-02-11T18:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nIndividual",
        "color": "red",
        "timed": true,
        "start": "2022-02-18T17:00:00.000Z",
        "end": "2022-02-18T18:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nIndividual",
        "color": "red",
        "timed": true,
        "start": "2022-02-25T17:00:00.000Z",
        "end": "2022-02-25T18:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nT 12",
        "color": "red",
        "timed": true,
        "start": "2022-02-04T18:00:00.000Z",
        "end": "2022-02-04T19:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nT 12",
        "color": "red",
        "timed": true,
        "start": "2022-02-11T18:00:00.000Z",
        "end": "2022-02-11T19:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nT 12",
        "color": "red",
        "timed": true,
        "start": "2022-02-18T18:00:00.000Z",
        "end": "2022-02-18T19:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nT 12",
        "color": "red",
        "timed": true,
        "start": "2022-02-25T18:00:00.000Z",
        "end": "2022-02-25T19:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nExpress Eliney ",
        "color": "red",
        "timed": true,
        "start": "2022-02-05T11:00:00.000Z",
        "end": "2022-02-05T12:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nExpress Eliney ",
        "color": "red",
        "timed": true,
        "start": "2022-02-12T11:00:00.000Z",
        "end": "2022-02-12T12:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nExpress Eliney ",
        "color": "red",
        "timed": true,
        "start": "2022-02-19T11:00:00.000Z",
        "end": "2022-02-19T12:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nExpress Eliney ",
        "color": "red",
        "timed": true,
        "start": "2022-02-26T11:00:00.000Z",
        "end": "2022-02-26T12:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nT 11",
        "color": "red",
        "timed": true,
        "start": "2022-02-05T13:00:00.000Z",
        "end": "2022-02-05T14:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nT 11",
        "color": "red",
        "timed": true,
        "start": "2022-02-12T13:00:00.000Z",
        "end": "2022-02-12T14:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nT 11",
        "color": "red",
        "timed": true,
        "start": "2022-02-19T13:00:00.000Z",
        "end": "2022-02-19T14:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nT 11",
        "color": "red",
        "timed": true,
        "start": "2022-02-26T13:00:00.000Z",
        "end": "2022-02-26T14:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nInterned - Renato",
        "color": "red",
        "timed": true,
        "start": "2022-02-05T14:00:00.000Z",
        "end": "2022-02-05T15:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nInterned - Renato",
        "color": "red",
        "timed": true,
        "start": "2022-02-12T14:00:00.000Z",
        "end": "2022-02-12T15:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nInterned - Renato",
        "color": "red",
        "timed": true,
        "start": "2022-02-19T14:00:00.000Z",
        "end": "2022-02-19T15:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nInterned - Renato",
        "color": "red",
        "timed": true,
        "start": "2022-02-26T14:00:00.000Z",
        "end": "2022-02-26T15:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nEG Vermelha - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-06T11:00:00.000Z",
        "end": "2022-02-06T12:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nEG Vermelha - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-13T11:00:00.000Z",
        "end": "2022-02-13T12:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nEG Vermelha - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-20T11:00:00.000Z",
        "end": "2022-02-20T12:00:00.000Z"
      },
      {
        "name": "Tipo Turma inicial edt - Quadra 2\nEG Vermelha - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-27T11:00:00.000Z",
        "end": "2022-02-27T12:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 2\nCard",
        "color": "red",
        "timed": true,
        "start": "2022-02-07T10:00:00.000Z",
        "end": "2022-02-07T11:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 2\nCard",
        "color": "red",
        "timed": true,
        "start": "2022-02-14T10:00:00.000Z",
        "end": "2022-02-14T11:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 2\nCard",
        "color": "red",
        "timed": true,
        "start": "2022-02-21T10:00:00.000Z",
        "end": "2022-02-21T11:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 2\nCard",
        "color": "red",
        "timed": true,
        "start": "2022-02-28T10:00:00.000Z",
        "end": "2022-02-28T11:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 2\nIntermed - Eliney",
        "color": "red",
        "timed": true,
        "start": "2022-02-07T21:00:00.000Z",
        "end": "2022-02-07T22:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 2\nIntermed - Eliney",
        "color": "red",
        "timed": true,
        "start": "2022-02-14T21:00:00.000Z",
        "end": "2022-02-14T22:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 2\nIntermed - Eliney",
        "color": "red",
        "timed": true,
        "start": "2022-02-21T21:00:00.000Z",
        "end": "2022-02-21T22:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 2\nIntermed - Eliney",
        "color": "red",
        "timed": true,
        "start": "2022-02-28T21:00:00.000Z",
        "end": "2022-02-28T22:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 2\nExpress Eliney ",
        "color": "red",
        "timed": true,
        "start": "2022-02-07T22:00:00.000Z",
        "end": "2022-02-07T23:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 2\nExpress Eliney ",
        "color": "red",
        "timed": true,
        "start": "2022-02-14T22:00:00.000Z",
        "end": "2022-02-14T23:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 2\nExpress Eliney ",
        "color": "red",
        "timed": true,
        "start": "2022-02-21T22:00:00.000Z",
        "end": "2022-02-21T23:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 2\nExpress Eliney ",
        "color": "red",
        "timed": true,
        "start": "2022-02-28T22:00:00.000Z",
        "end": "2022-02-28T23:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 2\nEG Vermelha - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-01T10:00:00.000Z",
        "end": "2022-02-01T11:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 2\nEG Vermelha - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-08T10:00:00.000Z",
        "end": "2022-02-08T11:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 2\nEG Vermelha - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-15T10:00:00.000Z",
        "end": "2022-02-15T11:00:00.000Z"
      },
      {
        "name": "Novo tipo - Quadra 2\nEG Vermelha - Icaro",
        "color": "red",
        "timed": true,
        "start": "2022-02-22T10:00:00.000Z",
        "end": "2022-02-22T11:00:00.000Z"
      }
    ].map(v => ({...v, start: new Date(v.start), end: new Date(v.end)})),
    events: [],
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
  }),
  mounted () {
    this.$refs.calendar.checkChange()
  },
  methods: {
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor (event) {
      return event.color
    },
    setToday () {
      this.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    updateRange ({ start, end }) {
      const events = []

      const min = new Date(`${start.date}T00:00:00`)
      const max = new Date(`${end.date}T23:59:59`)
      const days = (max.getTime() - min.getTime()) / 86400000
      const eventCount = this.rnd(days, days + 20)

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0
        const firstTimestamp = this.rnd(min.getTime(), max.getTime())
        const first = new Date(firstTimestamp - (firstTimestamp % 900000))
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
        const second = new Date(first.getTime() + secondTimestamp)

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        });
      }

      this.events = events
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
  },
}
</script>

<style scoped>

</style>